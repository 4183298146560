<template>
  <main class="content container checkout-container">
    <article class="cart">
      <h1 class="page-title text-center">Ihr Warenkorb</h1>
      <div v-if="itemStatus" class="row">
        <div class="col-lg-8">
          <!--cart item-->
          <div v-for="(item, index) in items" :key="index" class="cart__item">
            <div class="product-param">
              <div class="row product-param__inner">
                <!-- cart all info-->
                <div class="col-md-8">
                  <h2 class="title-secondary bold-weight mb-3">{{ item.name }}</h2>
                  <ItemSteps v-if="item.steps" :steps="item.steps"/>
                  <Quantity v-if="!item.multiple" :itemId="item.id"/>
                  <div class="cart__item-footer">
                    <div class="flex-shrink-0">
                      <button class="btn-reset btn-trash link-icon" @click="removeItem(item.id)"><i
                        class="fas fa-trash"></i>Löschen
                      </button>
                    </div>
                    <ItemPrice :discounted="item.price.discounted" :total="item.price.total"/>
                  </div>
                </div>
                <!--cart image product-->
                <div class="col-md-4">
                  <ItemImages :images="item.images" width="150px" height="72px"/>
                </div>
              </div>
            </div>
          </div>
          <Related/>
        </div>

        <div class="col-lg-4">
          <!-- cart info-->
          <div class="cart__info mt-4 mt-lg-0">
            <div class="title-secondary bold-weight mb-3">Versandkosten:</div>
            <Delivery />
            <div v-for="(item, index) in additionalOptions" :key="index" class="form-check">
              <input @change="setAdditional(item)" class="form-check-input" type="checkbox" :id="`ao_${index}`"
                     v-model="item.selected">
              <label class="form-check-label" :for="`ao_${index}`">
                {{ item.label }} +{{ $filters.currency(item.price) }}
                <Tooltip :tooltip="item.tooltip" class="float-right ml-1"><i class="fa fa-info-circle"></i></Tooltip>
              </label>
            </div>
<!--            <div class="сredit-info mb-4">-->
<!--              <div>-->
<!--                <i class="fas fa-info me-2 small-size"></i> oder <span class="text-danger">€135.32</span> pro Monat (für 48 x Monate)-->
<!--              </div>-->
<!--              <a data-bs-toggle="modal" href="#exampleModalToggle2" class="link">Mehr information</a>-->
<!--            </div>-->
            <Payment/>
            <Promocode/>
            <div class="card mb-4">
              <div class="card-body">
                <TotalCost title="Preis der Produkte" :discounted="price.discounted"
                           :total="price.total"/>
                <TotalCost title="Versandkosten" :total="price.delivery" class="total-red"/>
                <div class="mt-4"><strong>Gesamtsumme:</strong> <span class="text-ink">(inkl. 19% MwSt. & Versandkosten)</span></div>
                <TotalCost :title="zaunTitle" :discounted="price.discounted + price.delivery"
                           :total="price.total + price.delivery" class="grand-total"/>
                <TotalCost title="enthaltene MwSt" :total="price.total * (19 / 100)" class="mt-4"/>
                <TotalCost title="zu zahlen jetzt Anzahlung 20%" :total="price.total * (20 / 100)"/>
                <TotalCost v-if="promocodeStatus.status" :title="promoTitle" :total="price.discounted"
                           :discounted="promocodePrice" :percent="promocodeStatus.discount"/>
              </div>
            </div>
            <div class="row mb-4">
              <div class="w-auto mb-2">
                <router-link :to="{ name: 'home' }" class="btn btn-outline-primary min-width-170">Zurück</router-link>
              </div>
              <div class="w-auto mb-2">
                <router-link :to="{ name: 'checkout.customer' }" class="btn btn-primary min-width-170">Ihre Angaben ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-lg-12">
          <div class="alert alert-info">Leerer Korb!</div>
        </div>
      </div>
    </article>
    <!-- info box-->
    <InfoBox/>
  </main>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useMeta } from 'vue-meta'
import { useStore } from 'vuex'
import { onMounted } from '@vue/runtime-core'
import ItemSteps from './cart/itemSteps'
import ItemPrice from './cart/itemPrice'
import ItemImages from './cart/itemImages'
import InfoBox from './cart/infoBox'
import Delivery from './cart/delivery'
import TotalCost from './cart/totalCost'
import Promocode from './cart/promocode'
import Related from './cart/related'
import Quantity from './common/quantity'
import Payment from './payment/payment'
import { onBeforeMount } from 'vue'

export default {
  components: {
    ItemSteps,
    ItemPrice,
    ItemImages,
    InfoBox,
    Delivery,
    TotalCost,
    Promocode,
    Related,
    Quantity,
    Payment
  },
  setup () {
    useMeta({
      title: 'Warenkorb'
    })
    const store = useStore()
    // on mounted component
    onBeforeMount(() => {
      store.dispatch('checkout/setDeliveryCountries')
    })
    // ref
    const montageTitle = ref('Montage')
    const totalTitle = ref('Total')
    const zaunTitle = ref('Preis')
    const promoTitle = ref('Preis abzüglich Bonus-Code')
    // computed
    const items = computed(() => {
      return store.getters['checkout/items']
    })
    const price = computed(() => {
      return store.getters['checkout/price']
    })
    const montageStatus = computed(() => {
      return store.getters['checkout/montageStatus']
    })
    const montageCost = computed(() => {
      return store.getters['checkout/montageCost']
    })
    const montageBonus = computed(() => {
      return store.getters['checkout/montageBonus']
    })
    const promocodeStatus = computed(() => {
      return store.getters['checkout/promocode']
    })
    const itemStatus = computed(() => {
      if (items.value.length > 0) {
        return true
      }
      return false
    })
    const promocodePrice = computed(() => {
      if (promocodeStatus.value.status) {
        const discount = price.value.discounted * (promocodeStatus.value.discount / 100)
        const priceTotal = price.value.discounted - discount
        promocodeStatus.value.total = priceTotal
        return priceTotal
      }
      return price.value.discounted
    })
    const additionalOptions = computed(() => {
      return store.getters['checkout/additionalOptions']
    })

    // actions
    function removeItem (item) {
      store.dispatch('checkout/removeItem', item)
    }

    function setAdditional (item) {
      store.dispatch('checkout/setAdditionalOption', item)
    }

    onMounted(() => {
      additionalOptions.value.filter(item => {
        item.selected = false
      })
    })
    return {
      montageTitle,
      totalTitle,
      zaunTitle,
      promoTitle,
      price,
      montageStatus,
      montageCost,
      montageBonus,
      promocodeStatus,
      promocodePrice,
      items,
      itemStatus,
      additionalOptions,
      removeItem,
      setAdditional
    }
  }
}
</script>
